:root {
	--my-yellow: #fcb826;
	--my-dark-yellow: rgb(245, 168, 3);
}

.App {
	width: 100% !important;
	overflow-x: hidden;
}

/* Navbar */
.navbar {
	background-color: #18121e7a;
	padding: 0.5rem 0 !important;
}
.navbarBrand {
	color: var(--my-yellow) !important;
	font-weight: 600;
	letter-spacing: 1px;
	margin-left: 3rem;
}

.navItem {
	font-size: 1.2rem;
	font-weight: 400;
	margin-bottom: 0;
	padding-top: 0;
	line-height: 2.5;
	color: var(--my-yellow) !important;
}
.navItem:hover {
	color: #efefef !important;
	transition-duration: 400ms;
	text-shadow: 2px 2px #a9a9a9;
	cursor: pointer;
}
.navbar-toggler:focus {
	outline: none;
}

/* TopBox */

.topBoxChrome {
	height: 100vh;
	background-image: url(../img/homepage/trees.webp);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;
	padding-top: 40vh;
	color: lightcyan;
}

.topBoxOther {
	height: 100vh;
	background-image: url(../img/homepage/trees.jpeg);
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 40vh;
	color: white;
}

.topBoxLink {
	text-decoration: none;
	color: lightcyan;
}
.topBoxLink:hover {
	color: var(--my-dark-yellow);
	transition: 300ms;
}

.topBoxContent {
	width: 30vw;
	margin: auto;
}

/* Projects */

.projects {
	background-color: rgba(139, 139, 139, 0.5);
	color: #233237;
}

.projects h2 {
	color: #2d1941;
}


.projectRow {
	padding-top: 2rem;
	padding-bottom: 1rem;
}

.projectBox {
	width: 100vw;
}

.projectCard {
	width: 30% !important;
}

/* Contact Area */

#contact {
	background-color: #18121e;
}

#contact h2 {
	color: lightcyan;
	letter-spacing: 1px;
}

#formCard {
	background-color: transparent;
	padding: 20px;
}

#message {
	min-height: 120px;
}

.footer {
	background: rgb(27, 27, 27);
}

@media (max-width: 1166px) {
	.topBoxContent {
		width: 40vw;
	}
}

@media (max-width: 991px) {
	.navbarBrand {
		padding-left: 1rem !important;
		margin-left: 0 !important;
	}

	.topBoxContent {
		width: 50vw;
	}
	.projectRow {
		padding-top: 0;
	}
	.projectCard {
		width: 90% !important;
		margin-top: 20px;
	}
}

@media (max-width: 700px) {
	.topBoxContent {
		width: 75vw;
	}
}
@media (max-width: 468px) {
	.topBoxContent {
		width: 95vw;
	}
	.projectCard {
		width: 97% !important;
	}
}
